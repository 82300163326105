import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import Account from './index';
import { AiOutlineGlobal } from 'react-icons/ai';
import Central from '../../assets/images/central.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ModalNotice from './modalNotice';
import SETTINGS from '../../setting.json';
import backIcon from '../../assets/images/backicon.png';
import banner from '../../assets/images/banner33.png';
import classNames from 'classnames/bind';
import england from '../../assets/images/england.png';
import gg_fb from '../../assets/images/logo_gg_fbs.png';
import i18n from 'i18next';
import iconPass from '../../assets/images/icon-pass.png';
import iconShowPass from '../../assets/images/icon-show-pass.png';
import iconUser from '../../assets/images/icon-user.png';
import logoAgoda from '../../assets/images/sticker.webp';
import logoTitle from '../../assets/images/central-logo.png';
import styles from './Login.module.scss';
import { useTranslation } from 'react-i18next';
import vietnam from '../../assets/images/vietnam.png';

const axios = require('axios').default;

const cx = classNames.bind(styles);

function Login(props) {
    const [Lock, setLock] = useState(false);
    const [open, setOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [modalLanguage, setModalLanguage] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        document.title = props.title;
    }, [props.title]);

    const checkLock = () => {
        setLock(!Lock);
    };

    const handleLogin = (datas) => {
        let { username, password } = datas;
        let pattern = /^[0-9]*\d$/;
        if (!username || !password) return toast.warn(`${t('content.login.thieuThongTin')}`, { theme: 'light' });
        // if (!pattern.test(username)) return toast.warn(`${t('content.login.saiDinhDang')}`, { theme: 'light' });

        axios
            .post(`${SETTINGS.BASE_URL}/api/webapi/login`, {
                username: username,
                password: password,
            })
            .then(function (response) {
                let status = response.data.status;
                if (status === 'ok') {
                    setOpen(true);
                    localStorage.setItem('auth', response.data.auth);
                    // return toast.success(t('content.login.dangNhapTC'), { theme: 'light' });
                }
                if (status === 'error' && response.data.message === 'Tài khoản hoặc mật khẩu không chính xác')
                    return toast.error(t('content.login.saiTT'), { theme: 'light' });

                if (status === 'error' && response.data.message === 'Tài khoản đã bị khóa !')
                    return toast.error(t('content.login.tkBiKhoa'), { theme: 'light' });
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    };

    const changeLanguage = (value) => {
        setModalLanguage(false);
        i18n.changeLanguage(value);
        localStorage.setItem('language', value);
    };

    return (
        <div className="relative">
            <div className="flex items-center justify-between pt-8 header-account">
                <img src={backIcon} alt="" width={20} />
                <Link className="text-white fs-3" to="/register">
                    {t('content.login.taoTKM')}
                </Link>
            </div>

            <div className="relative float-right mt-4">
                <AiOutlineGlobal size={30} color="white" onClick={() => setModalLanguage(!modalLanguage)} />

                {modalLanguage && (
                    <div className="absolute top-[40px] right-[0px] flex flex-col items-center justify-center w-[120px] bg-white text-xl rounded-xl mx-2 z-[999]">
                        <div
                            className="flex items-center justify-between px-4 w-[100%] h-[36px]"
                            style={{ borderBottom: '1px solid #d2d2d2' }}
                            onClick={() => changeLanguage('vi')}
                        >
                            <span>Tiếng Việt</span>
                            <img src={vietnam} alt="" width={24} />
                        </div>
                        <div
                            className="flex items-center justify-between px-4 w-[100%] h-[36px]"
                            onClick={() => changeLanguage('en')}
                        >
                            <span>English</span>
                            <img src={england} alt="" width={24} />
                        </div>
                    </div>
                )}
            </div>

            <div className={`${cx('side-content')} min-[390px]:mt-[100px] max-[389px]:mt-[100px]`}>
                <div className="img-logo flex items-center justify-center pb-[100px]">
                    <img src="/temu/logo.png" alt="" className="w-[250px]" />
                </div>
                <div className={cx('form-group')}>
                    <img src={iconUser} alt="Icon User" className={cx('icon-left')} />
                    <input
                        className={cx('form-input')}
                        onInput={(e) => {
                            setUsername(e.target.value);
                        }}
                        type="number"
                        name="phoneNumber"
                        placeholder={t('content.login.sdtDN')}
                        autoComplete="off"
                        maxLength="128"
                        spellCheck="false"
                    />
                </div>
                <div className={cx('form-group')}>
                    <img src={iconPass} alt="Icon User" className={cx('icon-left')} />
                    <input
                        className={cx('form-input')}
                        onInput={(e) => {
                            setPassword(e.target.value);
                        }}
                        name="password"
                        type={Lock ? 'text' : 'password'}
                        placeholder={t('content.login.matKhauDN')}
                        autoComplete="new-password"
                        maxLength="128"
                    />
                    <img src={iconShowPass} alt="Icon User" className={cx('icon-right')} onClick={checkLock} />
                </div>

                {/* <div className="w-[100%] flex items-center justify-end mb-8 max-w-[280px]">
                    <Link className="text-white fs-4" to="https://t.me/cskhAgoda6868" target="_blank">
                        {t('content.login.quenMK')}?
                    </Link>
                </div> */}

                <div className={cx('btn-submit')}>
                    <button
                        type="button"
                        className={cx('block-events')}
                        onClick={() => handleLogin({ username, password })}
                    >
                        {t('content.login.dangNhap')}
                    </button>
                </div>
            </div>

            {/* <div className="img-logo flex items-center justify-center pb-[100px]">
                <img src={logoAgoda} alt="" className="w-[200px]" />
            </div> */}
            <div
                className="absolute text-white top-[85dvh] w-full text-center text-2xl font-bold "
                to="https://t.me/cskhvietnambooking6868"
                target="_blank"
            >
                <p className="">{t('all.phien_ban')}: Temu VietNam 2024.42 Build 4024</p>
            </div>
            <div
                className="absolute text-white top-[92dvh] w-full text-center text-xl  "
                to="https://t.me/cskhvietnambooking6868"
                target="_blank"
            >
                <p className="mb-0">@Copyright 2012-2024 Temu VietNam</p>
                <p className="mb-0">{t('all.moi_quyen_duoc_bao_luu')}</p>
            </div>
            <ModalNotice
                open={open}
                handleClose={() => {
                    setOpen(false);
                    window.location.href = '/';
                }}
            />

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Login;
