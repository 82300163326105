import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from 'react';

import SETTINGS from '../../setting.json';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';

const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' VNĐ';
}

const formatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
});

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function randomStr(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function Recharge({ title }) {
    document.title = title;
    let [show, setShow] = useState(true);
    let [select, setSelect] = useState('bank');
    let [money, setMoney] = useState(0);
    let [user, setUser] = useState([]);
    let id_txn = randomStr(16);
    const [adminBank, setAdminBank] = useState({});
    const { t } = useTranslation();
    let [showInfoBank, setShowInfoBank] = useState(false);

    function showInfoBankFunction() {
        setShowInfoBank(true);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setUser(data.data[0]);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/admin/bank`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                setAdminBank(data?.result?.list?.[0]);
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);
    function RechargeHandler() {
        if (!money || money < adminBank?.min_withdraw)
            return toast.warn(`${t('content.recharge.minNap')} ${formatMoney(adminBank?.min_withdraw)}`, {
                theme: 'light',
            });
        if (select === 'momo') return toast.warn(`${t('content.recharge.baoTriMomo')}`, { theme: 'light' });

        setShow(false);

        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/recharge/add`,
                { money, select, id_txn },
                {
                    headers,
                },
            )
            .then(function (response) {
                let data = response.data;
                setShow(true);
                if (data.status === 1) {
                    toast.success(data.message, { theme: 'light' });
                    setTimeout(() => {
                        window.location.href = `/`;
                    }, 1500);
                }
                if (data.status !== 1) toast.warn(data.message, { theme: 'light' });
                // if (data.status) {
                //     setTimeout(() => {
                //         // window.location.href = `/recharge/${data.id_txn}`;
                //         window.open(
                //             '/support',
                //             '_blank', // <- This is what makes it open in a new window.
                //         );
                //     }, 1500);
                // } else {
                //     localStorage.removeItem('auth');
                //     window.location.href = '/login';
                // }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }
    return (
        <div>
            <div className="recharge">
                <div>
                    <p className="my-amount text-[15px] font-semibold text-[#ffa900] bg-[#fff8e9] py-[10px] text-center rounded-md mt-8">
                        {t('content.recharge.soDuCT')}: {formatMoney(user.money) || '0'}
                    </p>
                    {showInfoBank ? (
                        <div className="text-center my-[25px]">
                            {/* <p className="text-3xl text-[#005652] font-bold">Nạp tiền</p> */}
                            <div className="form-group p-[15px] mt-[25px] bg-zinc-100 rounded-md">
                                <div className="flex items-center justify-between">
                                    <span className="text-2xl">{t('content.recharge.donHangSo')}: </span>
                                    <span className="text-2xl">{id_txn}</span>
                                    <div
                                        onClick={() => {
                                            toast.success(`${t('content.formRecharge.saoChepThanhCong')}`, {
                                                theme: 'light',
                                            });
                                            copy(id_txn);
                                        }}
                                        className="flex items-center justify-center rounded-full bg-cyan-500"
                                    >
                                        <p className="text-[#fff] p-2 mb-0">{t('content.recharge.saoChep')}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="flex items-center justify-between">
                                    <span className="text-2xl">{t('content.recharge.tenNH')}: </span>

                                    <span className="text-2xl w-[30%]">{`${
                                        money < 1000000 ? adminBank?.name_bank : adminBank?.name_bank1m
                                    }`}</span>
                                    <div
                                        onClick={() => {
                                            toast.success(`${t('content.formRecharge.saoChepThanhCong')}`, {
                                                theme: 'light',
                                            });
                                            copy(money < 1000000 ? adminBank?.name_bank : adminBank?.name_bank1m);
                                        }}
                                        className="flex items-center justify-center rounded-full bg-cyan-500"
                                    >
                                        <p className="text-[#fff] p-2 mb-0">{t('content.recharge.saoChep')}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="flex items-center justify-between">
                                    <span className="text-2xl">{t('content.recharge.tenNN')}: </span>

                                    <span className="text-2xl">
                                        {money < 1000000 ? adminBank?.name_u_bank : adminBank?.name_u_bank1m}
                                    </span>
                                    <div
                                        onClick={() => {
                                            toast.success(`${t('content.formRecharge.saoChepThanhCong')}`, {
                                                theme: 'light',
                                            });
                                            copy(money < 1000000 ? adminBank?.name_u_bank : adminBank?.name_u_bank1m);
                                        }}
                                        className="flex items-center justify-center rounded-full bg-cyan-500"
                                    >
                                        <p className="text-[#fff] p-2 mb-0">{t('content.recharge.saoChep')}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="flex items-center justify-between">
                                    <span className="text-2xl">{t('content.recharge.soTK')}: </span>

                                    <span className="text-2xl">
                                        {money < 1000000 ? adminBank?.stk_bank : adminBank?.stk_bank1m}
                                    </span>
                                    <div
                                        onClick={() => {
                                            toast.success(`${t('content.formRecharge.saoChepThanhCong')}`, {
                                                theme: 'light',
                                            });
                                            copy(money < 1000000 ? adminBank?.stk_bank : adminBank?.stk_bank1m);
                                        }}
                                        className="flex items-center justify-center rounded-full bg-cyan-500"
                                    >
                                        <p className="text-[#fff] p-2 mb-0">{t('content.recharge.saoChep')}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="flex justify-between ">
                                    <span className=" text-[#2a313c] font-semibold text-2xl">
                                        {t('content.recharge.soTien')}:
                                    </span>
                                    <input
                                        disabled
                                        onChange={(e) => setMoney(e.target.value.replace(/\D/g, ''))}
                                        className="text-right pr-[5px] text-[18px]"
                                        value={Number(money || 0).toLocaleString()}
                                        type="text"
                                        placeholder={t('content.recharge.nhapSoTien')}
                                        spellCheck="false"
                                        maxLength="13"
                                        style={{
                                            backgroundColor: '#d8ebfe',
                                            borderRadius: '5px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                </div>
                                <hr />
                                <span className="text-xl">{t('content.recharge.chuY')}</span>
                                <hr />
                                <img src={adminBank?.link_image_qr} alt="" className="rounded-2xl" />
                            </div>

                            {show && (
                                <div onClick={() => RechargeHandler()} className="w-[100%] mx-auto  mt-[25px] ">
                                    <div className="py-[10px] text-[#fff]  font-semibold rounded-md text-3xl bg-[#ff8000] cursor-pointer">
                                        {t('content.recharge.xacNhan')}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <div className="text-center my-[25px]">
                                <div className="form-group p-[15px] mt-[25px] bg-zinc-100 rounded-md ">
                                    <div className="mb-4 text-2xl">{t('all.nhap_so_tien_muon_nap')}:</div>
                                    <div className="flex justify-between ">
                                        <p className=" text-[#2a313c] font-semibold text-2xl">{t('all.so_tien')}:</p>
                                        <input
                                            onChange={(e) => setMoney(e.target.value)}
                                            className="text-right pr-[5px] text-[18px]"
                                            type="tel"
                                            placeholder={t('content.recharge.nhapSoTien')}
                                            spellCheck="false"
                                            maxLength="13"
                                            style={{
                                                backgroundColor: '#d8ebfe',
                                                borderRadius: '5px',
                                                marginLeft: '10px',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    onClick={() => showInfoBankFunction()}
                                    className="w-[50%] mx-auto px-[15px] mt-[25px] "
                                >
                                    <div className="py-[10px] text-[#fff]  font-semibold rounded-md text-3xl bg-[#ff8000]">
                                        {t('all.dong_y')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </div>
    );
}

export default Recharge;
