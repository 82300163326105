import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ModalNotice({ open, handleClose }) {
    const { t } = useTranslation();
    return (
        <Modal isOpen={open} toggle={handleClose} size="sm" centered>
            <ModalHeader toggle={handleClose} className="text-[16px]">
                <span className="text-[20px]">{t('all.thong_bao_temu')}</span>
            </ModalHeader>
            <ModalBody>
                <p className="text-[13px] text-justify">{t('all.content_notice_temu')}</p>
                <p
                    onClick={handleClose}
                    className="text-[20px] w-[180px] py-1 bg-[#ff8000] text-white rounded-[20px] text-center mx-auto cursor-pointer"
                >
                    OK
                </p>
            </ModalBody>
        </Modal>
    );
}
