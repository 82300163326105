import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import copy from 'copy-to-clipboard';
import facebook from '../../assets/images/facebook.png';
import instagram from '../../assets/images/instagram.png';
import line from '../../assets/images/line.png';
import twitter from '../../assets/images/twitter.png';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Invite(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation();

    const [inviteCode, setInviteCode] = useState(location.state.inviteCode);

    const { t } = useTranslation();

    return (
        <div className="withdraw px-[15px] py-[20px] min-h-[920px] bg-login h-[auto]">
            <div className="bg-[#fff] text-2xl rounded-2xl py-4 px-4 leading-10 mt-8">
                <div className="flex items-center justify-center w-[100%]">
                    <span
                        className="w-[60%] py-2 rounded-l-full px-8"
                        style={{ borderRight: 'none !important', border: '1px solid #c9c9c9' }}
                    >
                        {inviteCode}
                    </span>
                    <span
                        className="w-[40%] bg-[#ff8000] text-white py-2 rounded-r-full flex items-center justify-center"
                        style={{ borderLeft: '1px solid #ff8000', border: '1px solid #ff8000' }}
                        onClick={() => {
                            copy(inviteCode);
                            return toast.success(t('content.invite.saoChepThanhCong'), 'light');
                        }}
                    >
                        {t('content.invite.saoChep')}
                    </span>
                </div>

                <div className="text-[#ff8000] mt-8 flex items-center justify-center">{t('content.invite.chiaSe')}</div>

                <div className="flex items-center justify-center mt-4 mb-12">
                    <img src={line} alt="" width={40} height={40} className="mx-2 rounded-xl" />
                    <img src={facebook} alt="" width={40} height={40} className="mx-2 rounded-xl" />
                    <img src={instagram} alt="" width={40} height={40} className="mx-2 rounded-xl" />
                    <img src={twitter} alt="" width={40} height={40} className="mx-2 rounded-xl" />
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Invite;
