import './Navbar.css';

import { Link, Outlet } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';

import { FaRegListAlt } from 'react-icons/fa';
import { MdHome } from 'react-icons/md';
import balance from '../../../../assets/images/account_balance_wallet.png';
import balanceActive from '../../../../assets/images/account_balance_wallet_active.png';
import homeActive from '../../../../assets/images/home-icon-png-red.png';
import homeBlack from '../../../../assets/images/home-icon-png-black.png';
import nhandon from '../../../../assets/images/nhandon.png';
import nhandonNoActive from '../../../../assets/images/nhandon_noactive.png';
import { useTranslation } from 'react-i18next';
import userIcon from '../../../../assets/images/account_circle.png';
import userIconActive from '../../../../assets/images/account_circle_active.png';

function Navbar(props) {
    let link = props.link;
    let [homeN, setHome] = useState(link === '/' ? true : false);
    let [historyN, setHistory] = useState(link === '/order/index' ? true : false);
    let [userN, setUserN] = useState(link === '/my' ? true : false);
    let [productN, setProductN] = useState(link === '/order/mission' ? true : false);
    let [cskhN, setCSKHN] = useState(link === '/my/wallet' ? true : false);

    const { t } = useTranslation();

    useEffect(() => {
        if (link.indexOf('/order/mission') >= 0) {
            setHome(false);
            setHistory(false);
            setUserN(false);
            setCSKHN(false);
            setProductN(true);
        } else if (link === '/my') {
            setHome(false);
            setHistory(false);
            setUserN(true);
            setCSKHN(false);
            setProductN(false);
        } else if (link === '/system') {
            setHome(false);
            setHistory(false);
            setUserN(false);
            setCSKHN(false);
            setProductN(true);
        } else if (link === '/order/index') {
            setHome(false);
            setHistory(true);
            setUserN(false);
            setCSKHN(false);
            setProductN(false);
        } else if (link === '/my/wallet') {
            setHome(false);
            setHistory(false);
            setUserN(false);
            setCSKHN(true);
            setProductN(false);
        } else if (link === '/') {
            setHome(true);
            setHistory(false);
            setUserN(false);
            setCSKHN(false);
            setProductN(false);
        }
    }, [link]);

    return (
        <nav className="w-full navbar__footer fixed bottom-0 z-[49] h-[68px] max-w-[480px]">
            <div className="flex items-center justify-center navbar__footer--container">
                <Link to="/" className="flex justify-center flex-1 text-center">
                    <div className="flex flex-col justify-center flex-1 py-2 text-center">
                        <div className="flex justify-center bar-item__icon ">
                            {!homeN ? (
                                <i className="flex justify-center">
                                    <img src={homeBlack} alt="Home" className="w-9 h-9 sepia-0" />
                                </i>
                            ) : (
                                <i className="flex justify-center">
                                    <img src={homeActive} alt="Home" className="w-9 h-9 sepia-0" />
                                </i>
                            )}
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[3px]"
                            style={{ color: `rgb(${!homeN ? '114,114,114' : '255, 128, 0'})` }}
                        >
                            {t('content.nav.trangChu')}
                        </div>
                    </div>
                </Link>

                <Link to="/order/index" className="flex justify-center flex-1 text-center">
                    <div className="flex flex-col justify-center flex-1 py-2 text-center">
                        <div className="flex justify-center bar-item__icon">
                            {!historyN ? (
                                <FaRegListAlt color="#999999" size={22.5} />
                            ) : (
                                <FaRegListAlt color="#FF8000" size={22.5} />
                            )}
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[3px]"
                            style={{ color: `rgb(${!historyN ? '114,114,114' : '255, 128, 0'})` }}
                        >
                            {t('content.nav.lichSu')}
                        </div>
                    </div>
                </Link>

                <Link to="/order/mission" className="flex justify-center flex-1 text-center ">
                    <div className="flex flex-col justify-center flex-1 py-2 text-center">
                        <div className="flex justify-center bar-item__icon">
                            {!productN ? (
                                <i className="flex justify-center">
                                    <img src={nhandonNoActive} alt="Order" className="w-9 h-9 sepia-0 " />
                                </i>
                            ) : (
                                <i className="flex justify-center">
                                    <img src={nhandon} alt="Order" className="w-9 h-9 sepia-0 " />
                                </i>
                            )}
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[3px]"
                            style={{ color: `rgb(${!productN ? '114,114,114' : '255, 128, 0'})` }}
                        >
                            {t('content.nav.guiDon')}
                        </div>
                    </div>
                </Link>
                <Link to="/my/wallet" className="flex justify-center flex-1 text-center">
                    <div className="flex flex-col justify-center flex-1 py-2 text-center">
                        <div className="flex justify-center bar-item__icon">
                            {!cskhN ? (
                                <i className="flex justify-center">
                                    <img src={balance} alt="CSKH" className="w-9 h-9 sepia-0" />
                                </i>
                            ) : (
                                <i className="flex justify-center">
                                    <img src={balanceActive} alt="CSKH" className="w-9 h-9 sepia-0" />
                                </i>
                            )}
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[3px]"
                            style={{ color: `rgb(${!cskhN ? '114,114,114' : '255, 128, 0'})` }}
                        >
                            {t('content.nav.viTien')}
                        </div>
                    </div>
                </Link>

                <Link to="/my" className="flex justify-center flex-1 text-center">
                    <div className="flex flex-col justify-center flex-1 py-2 text-center">
                        <div className="flex justify-center bar-item__icon">
                            {!userN ? (
                                <i className="flex justify-center">
                                    <img src={userIcon} alt="My" className="w-9 h-9 sepia-0" />
                                </i>
                            ) : (
                                <i className="flex justify-center">
                                    <img src={userIconActive} alt="My" className="w-9 h-9 sepia-0" />
                                </i>
                            )}
                        </div>
                        <div
                            className="text-xl lg:text-2xl mt-[3px]"
                            style={{ color: `rgb(${!userN ? '114,114,114' : '255, 128, 0'})` }}
                        >
                            {t('content.nav.taiKhoan')}
                        </div>
                    </div>
                </Link>
            </div>
            <Outlet />
        </nav>
    );
}

export default memo(Navbar);
